import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormActionWrapperProps, FormRendererMode } from '../../../../contexts/FormRendererDesignContextTypes';
import ContextMenu, { ContextMenuItem } from '../../../shared/ContextMenu';
import { useTranslation } from 'react-i18next';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { EventSystem } from '../../../../events/EventSystem';
import CommentIcon from '../../../shared/icon/CommentIcon';
import TrashIcon from '../../../shared/icon/TrashIcon';
import { nextTick } from '../../../../utils/ReactUtils';
import CommentEvent from '../../../../events/QuestionCommentEvent';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '../../../shared/icon/DragHandleIcon';
import NumberListIcon from '../../../shared/icon/NumberListIcon';
import Tooltip from '../../../shared/Tooltip';
import { NumberingLevel } from '../../../../models/Form';
import Loader from '../../../shared/Loader';
import { useFormSectionRendererInfo } from '../../../../contexts/FormSectionRendererContext';
import { useCurrentActivityCommentsStats } from '../../../../global-state/Forms';

const AdHocActionWrapper: FC<FormActionWrapperProps & { readonly?: boolean }> = (props) => {
  const { children, readonly } = props;
  const { t } = useTranslation(['form', 'activity-type']);
  const { removeAdHocAnswer, clientFormId, toHighlight, onAdHocAnswer, answersBusySaving } = useFormRendererInfo();
  const { mode } = useFormSectionRendererInfo();
  const { currentAction, currentSection } = useFormAction();
  const commentStats = useCurrentActivityCommentsStats((x) => x.value);

  const [highlighted, setHighlighted] = useState(false);
  const selfRef = useRef<HTMLDivElement | null>(null);

  const commentCount = useMemo(
    () => commentStats.actionCommentsCounts[currentAction.fieldId!],
    [commentStats.actionCommentsCounts, currentAction.fieldId],
  );

  useEffect(() => {
    if (toHighlight) {
      const isHighlighted =
        toHighlight.formId === clientFormId && toHighlight.sectionId === currentSection.id && currentAction.fieldId === toHighlight.sourceId;
      setHighlighted(isHighlighted);
      if (isHighlighted && commentCount > 0 && !toHighlight.isRisk) {
        EventSystem.fireEvent('question-comment-open', {
          sourceId: currentAction.fieldId!,
          sectionId: currentSection.id,
        });
      }
    }
  }, [clientFormId, commentCount, currentAction.fieldId, currentAction.id, currentSection.id, toHighlight]);

  useEffect(() => {
    if (highlighted) {
      nextTick(() => selfRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' }));
    }
  }, [highlighted]);

  useEffect(() => {
    const handleCommentOpen = (event: CommentEvent) => {
      setHighlighted(currentAction.fieldId === event.sourceId);
    };

    const handleCommentCancel = () => {
      setHighlighted(false);
    };

    EventSystem.listen('question-comment-open', handleCommentOpen);
    EventSystem.listen('comment-cancel', handleCommentCancel);
    EventSystem.listen('question-comment-new', handleCommentOpen);

    return () => {
      EventSystem.stopListening('question-comment-open', handleCommentOpen);
      EventSystem.stopListening('comment-cancel', handleCommentCancel);
      EventSystem.stopListening('question-comment-new', handleCommentOpen);
    };
  }, [currentAction.fieldId, currentAction.id]);

  const { attributes, listeners, transform, transition, setNodeRef, setActivatorNodeRef, isDragging } = useSortable({
    id: currentAction.fieldId as string,
  });

  const contextItems = useMemo<ContextMenuItem[]>(() => {
    return [
      {
        title: t('action-menu.comment'),
        icon: <CommentIcon className="h-4 w-4" />,
        onClick: () => {
          EventSystem.fireEvent('question-comment-new', {
            sourceId: currentAction.fieldId!,
            sectionId: currentSection.id,
          });
        },
      },
      {
        title: t('activity-type:ad-hoc-fields.context-menu.remove'),
        icon: <TrashIcon className="h-4 w-4" />,
        onClick: () => {
          removeAdHocAnswer(currentAction.id, currentAction.fieldId!);
        },
        hide: readonly,
      },
    ];
  }, [currentAction.fieldId, currentAction.id, currentSection.id, readonly, removeAdHocAnswer, t]);

  const savingCurrentField = useMemo(
    () => mode === FormRendererMode.EditView && currentAction.fieldId && answersBusySaving[currentAction.fieldId],
    [answersBusySaving, currentAction.fieldId, mode],
  );

  return (
    <div
      ref={(ref) => {
        selfRef.current = ref;
        setNodeRef(ref);
      }}
      style={{ transform: CSS.Translate.toString(transform), transition }}
      className={`group/ad-hoc-wrapper ${highlighted ? 'bg-accent-light-mid' : ''} ${
        !highlighted ? 'hover:bg-gray-100 [&:has(:focus)]:bg-gray-100' : ''
      } relative my-1 flex items-center rounded-lg px-2 ${isDragging ? 'z-50' : ''}`}
    >
      {!readonly && !currentAction.data?.useDocumentNumbering && !savingCurrentField && (
        <div className="group/ad-hoc-numbering absolute left-2 top-2 flex">
          <Tooltip text={t('activity-type:ad-hoc-fields.numbering-system-tooltip')}>
            {(tooltip) => (
              <span {...tooltip}>
                <NumberListIcon className="border-gray-2 bg-gray-2 invisible h-7 w-7 rounded border p-[0.125rem] text-white group-hover/ad-hoc-wrapper:visible group-hover/ad-hoc-numbering:rounded-r-none" />
              </span>
            )}
          </Tooltip>
          <div className="border-gray-2 text-dpm-12 z-50 hidden rounded-r border-b border-r border-t bg-white group-hover/ad-hoc-numbering:flex">
            <button
              className={`border-gray-2 hover:bg-gray-5 flex cursor-pointer items-center border-r p-[0.125rem] ${currentAction.numberingLevel === NumberingLevel.Below ? 'bg-accent-4' : ''}`}
              onClick={() =>
                onAdHocAnswer(currentAction.id, currentAction.fieldId!, {
                  numberingLevel: currentAction.numberingLevel === NumberingLevel.Below ? NumberingLevel.None : NumberingLevel.Below,
                })
              }
            >
              {currentAction.nextNumbers?.below ?? '1.1.1.'}
            </button>
            <button
              className={`hover:bg-gray-5 flex cursor-pointer items-center rounded-r p-[0.125rem] ${currentAction.numberingLevel === NumberingLevel.Current ? 'bg-accent-4' : ''}`}
              onClick={() =>
                onAdHocAnswer(currentAction.id, currentAction.fieldId!, {
                  numberingLevel: currentAction.numberingLevel === NumberingLevel.Current ? NumberingLevel.None : NumberingLevel.Current,
                })
              }
            >
              {currentAction.nextNumbers?.current ?? '1.1.'}
            </button>
          </div>
        </div>
      )}

      {!readonly && !savingCurrentField && (
        <div
          ref={setActivatorNodeRef}
          {...listeners}
          {...attributes}
          className={`hover:bg-gray-3 invisible mt-6 w-7 flex-shrink-0 rounded-md p-2 group-hover/ad-hoc-wrapper:visible ${
            isDragging ? 'bg-gray-3 cursor-grabbing' : 'cursor-grab'
          }`}
        >
          <DragHandleIcon className="text-gray-2 h-6 w-3" />
        </div>
      )}
      {savingCurrentField && (
        <div className="w-7 p-2">
          <Loader size={6} centered={false} />
        </div>
      )}
      <div className="flex-grow p-2">{children}</div>
      <ContextMenu className="invisible flex-shrink-0 group-hover/ad-hoc-wrapper:visible" items={contextItems} />
    </div>
  );
};

export default AdHocActionWrapper;
